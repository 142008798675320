import { colors } from "@mui/material";
import { createTheme } from "@mui/material/styles";

export enum ThemeMode {
  Light = "light",
  Dark = "dark",
}

enum Wso2Theme {
  Primary = "#212a32",
  Secondary = "#ff7300",
}

export const lightTheme = createTheme({
  palette: {
    mode: ThemeMode.Light,
    background: {
      default: "#f4f6f8",
      paper: colors.common.white,
    },
    primary: {
      contrastText: colors.common.white,
      main: Wso2Theme.Primary,
      light: "#e9ecf0",
    },
    secondary: {
      contrastText: colors.common.white,
      main: Wso2Theme.Secondary,
    },
    text: {
      primary: "#000000de",
      secondary: "#00000099",
      disabled: "#00000061",
    },
    warning: {
      main: Wso2Theme.Secondary,
    },
  },
  typography: {
    fontFamily: "Montserrat"
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: ThemeMode.Dark,
    background: {
      paper: Wso2Theme.Primary,
      default: "#1b1f23",
    },
    primary: {
      contrastText: colors.common.white,
      main: Wso2Theme.Secondary,
      light: "#111",
    },
    secondary: {
      contrastText: colors.common.white,
      main: Wso2Theme.Primary,
    },
    text: {
      primary: colors.common.white,
      secondary: "#ffffffb3",
      disabled: "#ffffff80",
    },
    warning: {
      main: Wso2Theme.Secondary,
    },
  },
  typography: {
    fontFamily: "Montserrat"
  },
});
