import { useParams, useNavigate } from "react-router-dom";
import {
  useGetConnectorByIdQuery,
  useGetConnectorLogoQuery,
  useGetConnectorNamesQuery,
  useGetReleasesByConnectorIdQuery,
  useDownloadConnectorMutation,
} from "../apis/connectorApis";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setConnector } from "../store/connectorInfoSlice";
import { RootState } from "../store/rootReducer";
import { Box } from "@mui/system";
import CustomSnackbar from "../components/CustomSnackbar";
import {
  extractProminentFontColor,
  generateRandomIconColor,
  getApiErrorMsg,
  imageFromArray,
} from "../utils/utils";
import {
  Autocomplete,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  Icon,
  InputAdornment,
  Modal,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Connector,
  ConnectorName,
  Release,
  ConnectorAssetResponse,
} from "../types/types";
import { colors } from "@mui/material";
import { Link } from "react-router-dom";
import { LABEL } from "./Home";
import { setConnectorNames } from "../store/connectorNameSlice";
import {
  BugReportOutlined,
  ErrorOutlineSharp,
  LinkRounded,
  SearchSharp,
  VisibilitySharp,
  ArrowBackIosNewOutlined,
  BalanceOutlined,
} from "@mui/icons-material";
import PageFooter from "../components/PageFooter";
import ReleaseDescription from "../components/ReleaseDescription";
import ReactGA from "react-ga4";

const RELEASES_ROWS_PER_PAGE = 10;

const ConnectorInfo: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  let { id } = useParams<{ id: string }>();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [isConnectorNotFound, setIsConnectorNotFound] =
    useState<boolean>(false);
  const [connectorId, setConnectorId] = useState<string>(id ?? "");
  const [randomColor, setRandomColor] = useState<string>(
    theme.palette.background.paper
  );
  const [prominentColor, setProminentColor] = useState<string>("");
  const [versionSearchValue, setVersionSearchValue] = useState<string>("");
  const [supportFilterValue, setSupportFilterValue] = useState<string>("");
  const [image, setImage] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedRelease, setSelectedRelease] = useState<Release>();
  const isDarkTheme = useSelector((state: RootState) => state.theme);
  const connector = useSelector((state: RootState) => state.connector);
  const connectorNames = useSelector(
    (state: RootState) => state.connectorNames
  );

  const [downloadConnectorMutation] = useDownloadConnectorMutation();

  const startIndex = page * RELEASES_ROWS_PER_PAGE;
  const endIndex = startIndex + RELEASES_ROWS_PER_PAGE;

  const {
    data: connectorResponse,
    error: connectorError,
    isFetching: connectorLoading,
  } = useGetConnectorByIdQuery(connectorId ?? "");

  const {
    data: connectorNamesResponse,
    error: connectorNamesError,
    isLoading: connectorNamesLoading,
  } = useGetConnectorNamesQuery();

  const { data: logoResponse, isFetching: logoLoading } =
    useGetConnectorLogoQuery(connectorResponse?.repoName ?? "", {
      skip: !!connectorError || !connectorResponse?.repoName,
    });

  const {
    data: releaseResponse,
    error: releaseError,
    isFetching: releaseLoading,
  } = useGetReleasesByConnectorIdQuery(connectorResponse?.repoName ?? "", {
    skip: !!connectorError || !connectorResponse?.repoName,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (connectorResponse) {
      dispatch(setConnector(connectorResponse as Connector));
      if (connectorResponse.connectorName) {
        document.title = connectorResponse.connectorName;
      }
      if (!logoResponse) {
        setRandomColor(
          generateRandomIconColor(connectorResponse.connectorName)
        );
      }
    }
    if (connectorError) {
      setIsSnackbarOpen(true);
    }
    if (
      connectorError &&
      typeof connectorError === "object" &&
      "status" in connectorError &&
      connectorError.status === 404
    ) {
      setIsConnectorNotFound(true);
    }
  }, [connectorResponse, connectorError]);

  useEffect(() => {
    if (connectorNamesResponse) {
      dispatch(setConnectorNames(connectorNamesResponse));
    }
    if (connectorNamesError) {
      setIsSnackbarOpen(true);
    }
  }, [connectorNamesResponse, connectorNamesError]);

  useEffect(() => {
    if (releaseError) {
      setIsSnackbarOpen(true);
    }
  }, [releaseError]);

  useEffect(() => {
    setImage(imageFromArray(logoResponse?.blobContent));
  }, [logoResponse]);

  useEffect(() => {
    extractProminentFontColor(image)
      .then((color) => {
        if (color) {
          setProminentColor(color);
        }
      })
      .catch((error) => console.error(error));
  }, [image]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleConnectorNameSearch = (
    event: any,
    value: ConnectorName | string | null
  ) => {
    if (typeof value === "object" && value !== null) {
      const newUrl = `${window.config.FRONTEND_BASE_URL}/connector/${value.id}`;
      window.history.replaceState({ path: newUrl }, "", newUrl);
      setConnectorId(value.id);
    }
  };

  const handleSupportProductChange = (event: any, value: string | null) => {
    setSupportFilterValue(value ? value : "");
  };

  const filterReleases = (releaseResponse: Release[], searchValue: string) => {
    if (supportFilterValue != "") {
      releaseResponse = releaseResponse.filter(
        (release) => release.products.indexOf(supportFilterValue) >= 0
      );
    }
    if (searchValue !== "") {
      return releaseResponse.filter(
        (release) =>
          release.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          release.tagName.toLowerCase().includes(searchValue.toLowerCase()) ||
          (release.body &&
            release.body.toLowerCase().includes(searchValue.toLowerCase())) ||
          release.products.some((product) =>
            product.toLowerCase().includes(searchValue.toLowerCase())
          )
      );
    }
    return releaseResponse;
  };

  const getSupportProducts = () => {
    let products: string[] = [];
    if (releaseResponse && releaseResponse.length > 0) {
      releaseResponse.forEach((release: Release) => {
        products.push(...release.products);
      });
    }
    products = products.filter(
      (value, index, self) => self.indexOf(value) === index
    );
    products.sort((a, b) => a.localeCompare(b));
    return products;
  };

  const downloadConnector = async (id: string, tagName: string) => {
    try {
      const response = await downloadConnectorMutation(id);
      if (connector.connectorName) {
        // ReactGA.event({
        //   category: "Download",
        //   action: "Connector Download",
        //   label: connector.connectorName
        // });
      }
      window.open(
        (response as { data: ConnectorAssetResponse }).data.downloadUrl,
        "_blank"
      );
    } catch (error) {
      console.error("Connector download failed:", error);
    }
  };

  const openModal = (release: Release) => {
    setSelectedRelease(release);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: theme.palette.background.default,
        py: 4,
        px: 2,
      }}
    >
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <ReleaseDescription
          release={selectedRelease}
          handleClose={closeModal}
        />
      </Modal>
      <>
        <Box>
          <Grid container>
            <Grid item lg={8} md={6} xs={12}>
              <Button
                sx={{
                  color: isDarkTheme
                    ? theme.palette.primary.main
                    : theme.palette.primary.main,
                }}
                size="large"
                startIcon={<ArrowBackIosNewOutlined />}
                onClick={() => navigate(-1)}
              >
                <Typography sx={{ textTransform: "none" }}>Back</Typography>
              </Button>
            </Grid>
            <Grid item lg={4} md={6} xs={12} sx={{ textAlign: "end" }}>
              <FormControl
                fullWidth
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.background.paper,
                  mb: 2,
                }}
                variant="outlined"
                size="small"
              >
                <Autocomplete
                  freeSolo
                  loading={connectorNamesLoading}
                  size="small"
                  onChange={(event, value) =>
                    handleConnectorNameSearch(event, value)
                  }
                  options={connectorNames}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search by Connector Name"
                      value={""}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {connectorNamesLoading ? (
                              <CircularProgress color="primary" size={20} />
                            ) : (
                              <SearchSharp color="action" />
                            )}
                            <div>{params.InputProps.endAdornment}</div>
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  fullWidth
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </>
      <Card
        sx={{
          p: { xs: 2, md: 3 },
          backgroundImage: "none",
          borderLeft: connectorLoading ? 0 : 5,
          borderLeftColor: prominentColor || randomColor,
        }}
      >
        <CustomSnackbar
          open={isSnackbarOpen}
          onClose={() => {
            setIsSnackbarOpen(false);
          }}
          message={
            isConnectorNotFound
              ? "Sorry, the page you're looking for is not available."
              : getApiErrorMsg([connectorError || releaseError])
          }
          severity={"error"}
        />
        {connectorLoading ? (
          <Box
            sx={{
              textAlign: "center",
              height: "25vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {isConnectorNotFound ? (
              <div
                style={{
                  width: "100%",
                  height: "20vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ErrorOutlineSharp
                  color="error"
                  sx={{
                    fontSize: 70,
                    verticalAlign: "middle",
                    mr: 1,
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    pb: 2,
                    pt: 3,
                    fontWeight: 600,
                    color: theme.palette.error.main,
                  }}
                >
                  Sorry, the page you're looking for is not available.
                </Typography>
              </div>
            ) : (
              <Box>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    color={theme.palette.text.primary}
                    variant="h6"
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    {connector.connectorName}
                  </Typography>
                  <Box>
                    {connector.license && connector.licenseUrl && (
                      <Link to={connector.licenseUrl} target="_blank">
                        <Button
                          color="success"
                          variant="outlined"
                          size="small"
                          startIcon={<BalanceOutlined />}
                          sx={{ marginRight: 2 }}
                        >
                          <Typography sx={{ textTransform: "none" }}>
                            {connector.license}
                          </Typography>
                        </Button>
                      </Link>
                    )}
                    <Link to={`${connector.repoUrl}/issues`} target="_blank">
                      <Button
                        color="error"
                        variant="outlined"
                        size="small"
                        startIcon={<BugReportOutlined />}
                      >
                        <Typography sx={{ textTransform: "none" }}>
                          Report an Issue
                        </Typography>
                      </Button>
                    </Link>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box sx={{ width: { xs: "100%", md: "20%" } }}>
                    {!logoLoading && image ? (
                      <Box
                        sx={{
                          overflow: "hidden",
                          height: { xs: "50%", md: "100%" },
                          width: { xs: "50%", md: "100%" },
                          pb: { xs: 2, md: 0 },
                        }}
                      >
                        {!logoResponse ? (
                          <Typography
                            sx={{
                              backgroundColor: randomColor,
                              width: "100%",
                              height: "100%",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "6rem",
                              fontWeight: "bold",
                              borderRadius: "4px",
                              color: isDarkTheme
                                ? theme.palette.secondary.dark
                                : theme.palette.primary.dark,
                            }}
                          >
                            {connector.connectorName
                              ? connector.connectorName
                                  .split(" ")
                                  .slice(0, 2)
                                  .map((word) => word.charAt(0).toUpperCase())
                                  .join("")
                              : ""}
                          </Typography>
                        ) : (
                          <img
                            src={image}
                            alt={`${connector.connectorName} logo`}
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "4px",
                              objectFit: "contain",
                              textAlign: "center",
                            }}
                          />
                        )}
                      </Box>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Skeleton
                          variant="rounded"
                          width={"100%"}
                          height={"100%"}
                          animation="wave"
                        />
                      </div>
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      paddingLeft: { md: 4, sx: 0 },
                    }}
                  >
                    <Box>
                      <Typography
                        variant="subtitle2"
                        color={theme.palette.text.secondary}
                      >
                        Owner
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color={theme.palette.text.primary}
                        sx={{ fontWeight: "bold", pb: 2 }}
                      >
                        {connector.owner || "N/A"}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        color={theme.palette.text.secondary}
                      >
                        Summary
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color={theme.palette.text.primary}
                        sx={{ fontWeight: "bold", pb: 2 }}
                        textAlign="justify"
                      >
                        {connector.description || "N/A"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", pb: 2 }}>
                      <Typography
                        variant="subtitle2"
                        color={theme.palette.text.secondary}
                      >
                        Documentation
                      </Typography>
                      <Box
                        color={theme.palette.text.primary}
                        sx={{
                          pl: 0.5,
                          textAlign: "justify",
                          wordBreak: "break-all",
                          "&:hover": {
                            transform: "scale(1.08)",
                            transition: "transform 0.3s ease-in-out",
                          },
                        }}
                      >
                        <Link
                          style={{
                            color: isDarkTheme
                              ? theme.palette.primary.main
                              : theme.palette.secondary.main,
                          }}
                          to={connector.documentationUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LinkRounded />
                        </Link>
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        color={theme.palette.text.secondary}
                      >
                        Associated Labels
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          paddingTop: "6px",
                        }}
                      >
                        {connector.labels.length > 0 &&
                          connector.labels.map((label) => (
                            <Link
                              key={label}
                              style={{ textDecoration: "none" }}
                              to={`/?${LABEL}=${label}`}
                            >
                              <Typography
                                key={label}
                                variant="subtitle2"
                                color={colors.common.white}
                                sx={{
                                  fontWeight: "700",
                                  backgroundColor: isDarkTheme
                                    ? theme.palette.primary.main
                                    : theme.palette.secondary.main,
                                  borderRadius: "4px",
                                  pl: 1,
                                  pr: 1,
                                  mr: 0.5,
                                  cursor: "pointer",
                                  "&:hover": {
                                    transform: "scale(1.05)",
                                    transition: "transform 0.3s ease-in-out",
                                  },
                                }}
                              >
                                {label}
                              </Typography>
                            </Link>
                          ))}
                        {connector.labels.length == 0 && (
                          <Typography variant="subtitle2">
                            Not Available
                          </Typography>
                        )}
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </>
        )}
      </Card>
      {!isConnectorNotFound && (
        <Card
          sx={{
            p: { xs: 2, md: 3 },
            mt: 3,
            backgroundImage: "none",
          }}
        >
          {connectorLoading || releaseLoading ? (
            <Box
              sx={{
                textAlign: "center",
                height: "25vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Grid container>
                <Grid item lg={6} md={12} xs={12}>
                  <Typography
                    color={theme.palette.text.primary}
                    sx={{
                      pb: 1,
                      mb: 2,
                      fontWeight: 600,
                      fontSize: "1.1rem",
                    }}
                  >
                    Releases
                  </Typography>
                </Grid>
                <Grid item lg={2} md={5} xs={12} sx={{ pr: { lg: 2, md: 2 } }}>
                  <FormControl
                    fullWidth
                    sx={{
                      width: "100%",
                      backgroundColor: theme.palette.background.paper,
                      mb: 2,
                    }}
                    variant="outlined"
                    size="small"
                  >
                    <Autocomplete
                      loading={releaseLoading}
                      size="small"
                      onChange={(event, value) =>
                        handleSupportProductChange(event, value)
                      }
                      options={getSupportProducts()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Support Product"
                          value={""}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {connectorNamesLoading && (
                                  <CircularProgress color="primary" size={20} />
                                )}
                                <div>{params.InputProps.endAdornment}</div>
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={7} xs={12}>
                  <FormControl
                    fullWidth
                    sx={{
                      width: "100%",
                      backgroundColor: theme.palette.background.paper,
                      mb: 2,
                    }}
                    variant="outlined"
                    size="small"
                  >
                    <TextField
                      size="small"
                      onChange={(e) => setVersionSearchValue(e.target.value)}
                      placeholder="Search"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchSharp />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Box sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer
                  sx={{
                    boxShadow: 1,
                  }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography fontWeight="700">Release Name</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontWeight="700">Version</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontWeight="700">
                            Support Products
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontWeight="700">Description</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontWeight="700">Download</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {releaseResponse &&
                        filterReleases(releaseResponse, versionSearchValue)
                          .slice(startIndex, endIndex)
                          .map((release) => {
                            return (
                              <TableRow
                                hover
                                key={release.releaseId}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{release.name}</TableCell>
                                <TableCell>{release.tagName}</TableCell>
                                <TableCell>
                                  {release.products.length > 0 &&
                                    release.products
                                      .slice()
                                      .sort((a, b) => a.localeCompare(b))
                                      .map((product) => (
                                        <div key={product}>{product}</div>
                                      ))}
                                  {release.products.length == 0 && <>N/A</>}
                                </TableCell>
                                <TableCell>
                                  {release.body ? (
                                    <Tooltip
                                      title={"View Description"}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <VisibilitySharp
                                        onClick={() => openModal(release)}
                                      />
                                    </Tooltip>
                                  ) : (
                                    "N/A"
                                  )}
                                </TableCell>
                                <TableCell>
                                  {release.assets.map((asset) => (
                                    <div>
                                      <span
                                        key={asset.assetId}
                                        style={{
                                          color: isDarkTheme
                                            ? theme.palette.primary.main
                                            : theme.palette.secondary.main,
                                          cursor: "pointer",
                                          textDecoration: "underline",
                                        }}
                                        onClick={() =>
                                          downloadConnector(
                                            asset.assetId,
                                            release.tagName
                                          )
                                        }
                                      >
                                        {asset.name}
                                      </span>
                                    </div>
                                  ))}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                  {releaseResponse &&
                    filterReleases(releaseResponse, versionSearchValue)
                      .length == 0 && (
                      <Typography
                        variant="body2"
                        color={
                          isDarkTheme
                            ? theme.palette.primary.main
                            : theme.palette.secondary.main
                        }
                        sx={{
                          textAlign: "center",
                          width: "100%",
                          pt: 2,
                          pb: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Icon component={ErrorOutlineSharp} sx={{ mr: 1 }} />
                        No Releases Found
                      </Typography>
                    )}
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={releaseResponse ? releaseResponse.length : 0}
                  rowsPerPage={RELEASES_ROWS_PER_PAGE}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </Box>
            </Box>
          )}
        </Card>
      )}
      <PageFooter />
    </Box>
  );
};

export default ConnectorInfo;
