import React from "react";
import { Typography, useTheme } from "@mui/material";
import { APP_NAME, CURRENT_YEAR } from "../utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "../store/rootReducer";
import { Link } from "react-router-dom";

const PageFooterContent: React.FC<{}> = () => {
  const isDarkTheme = useSelector((state: RootState) => state.theme);
  const theme = useTheme();

  return (
    <Typography
      variant="body2"
      sx={{
        flexGrow: 1,
        fontSize: "0.8rem",
        fontWeight: 600,
        color: theme.palette.text.primary,
        pl: 1,
      }}
    >
      {APP_NAME} | © {CURRENT_YEAR}{" "}
      <Link
        to="https://wso2.com/"
        style={{
          color: isDarkTheme
            ? theme.palette.primary.main
            : theme.palette.secondary.main,
          fontWeight: 600
        }}
        target="_blank"
      >
        WSO2 LLC
      </Link> | { }
      <Link
        to="https://wso2.com/legal/"
        style={{
          color: isDarkTheme
            ? theme.palette.primary.main
            : theme.palette.secondary.main,
          fontWeight: 600
        }}
        target="_blank"
      >
        WSO2 Legal
      </Link>
    </Typography>
  );
};

export default PageFooterContent;
