import React from "react";
import { AppBar, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../store/rootReducer";
import PageFooterContent from "./PageFooterContent";

const PageFooter: React.FC<{}> = () => {
  const isDarkTheme = useSelector((state: RootState) => state.theme);
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      sx={{
        top: "auto",
        bottom: 0,
        height: "18px",
        backgroundImage: "none",
        backgroundColor: isDarkTheme
          ? theme.palette.secondary.main
          : theme.palette.primary.contrastText,
      }}
    >
      <PageFooterContent />
    </AppBar>
  );
};

export default PageFooter;
