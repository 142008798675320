import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface SnackbarProps {
  open: boolean;
  autoHideDuration?: number;
  onClose: () => void;
  message: string;
  severity?: AlertColor;
  action?: React.ReactNode;
}

const CustomSnackbar: React.FC<SnackbarProps> = ({
  open,
  autoHideDuration = 5000,
  onClose,
  message,
  severity,
  action,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      action={
        action ? (
          <>
            {action}
            <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        ) : (
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )
      }
    >
    <MuiAlert elevation={6} variant="filled" onClose={onClose} severity={severity as AlertColor}>
        {message}
    </MuiAlert>
    </Snackbar>
  );
};

export default CustomSnackbar;
